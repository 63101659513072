/* eslint-disable no-unused-vars */
import React from 'react';
import Skeleton from '@common_skeleton';
import GlobalPromoCarousel from '@core_modules/cms/components/cms-renderer/global-promo-carousel';
import cx from 'classnames';
import { features } from '@config';
import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import Link from 'next/link';
import Typography from '@common_typography';
import { getCompareList, getCustomerUid } from '@core_modules/productcompare/service/graphql';
import { getLoginInfo } from '@helper_auth';
import { getCookies } from '@helper_cookies';

const GlobalPromoMessage = (props) => {
    const {
        // prettier-ignore
        storeConfig,
        t,
        ...other
    } = props;
    const { key_cookies } = features.globalPromo;
    const isLogin = getLoginInfo();

    const { data, loading } = getCmsBlocks({
        identifiers: 'global_promo_message',
    });

    const { data: dataRightLink, loading: loadingRightLink } = getCmsBlocks({
        identifiers: 'top_header_right_link',
    });

    const reloadCompareQty = typeof window !== 'undefined' && window && window.reloadCompareQty;

    const [getProduct, {
        data: compareList, loading: loadingCompareList, refetch: refetchCompare,
    }] = getCompareList();
    const [getUid, { data: dataUid }] = getCustomerUid();

    React.useEffect(() => {
        if (isLogin) {
            getUid();
        }
    }, [isLogin]);

    React.useEffect(() => {
        if (!isLogin) {
            const uid = getCookies('uid_product_compare');
            if (uid) {
                getProduct({
                    variables: {
                        uid,
                    },
                });
            }
        }
    }, []);

    React.useEffect(() => {
        if (dataUid && dataUid?.customer?.compare_list && isLogin) {
            const uid = getCookies('uid_product_compare');
            if (uid) {
                const uid_product = dataUid?.customer?.compare_list?.uid;
                getProduct({
                    variables: {
                        uid: uid_product,
                    },
                });
            }
        }
    }, [dataUid]);

    React.useEffect(() => {
        if (reloadCompareQty) {
            refetchCompare();
            window.reloadCompareQty = false;
        }
    }, [reloadCompareQty]);

    const ProductCompareContent = () => {
        if (loadingCompareList && !compareList) {
            return (
                <Skeleton height={20} className="px-5 border-r border-neutral-100" />
            );
        }

        return (
            <>
                {!loadingRightLink && compareList?.compareList?.item_count ? (
                    <Link
                        href="/catalog/product_compare"
                        className="px-5 border-r border-neutral-100"
                    >
                        <Typography
                            variant="p-3"
                            className="text-sm font-medium whitespace-nowrap flex items-center text-neutral-500 hover:underline"
                        >
                            {t('common:productCompare:title')}
                            {' '}
                            (
                            <span id="compare_count">{compareList?.compareList?.item_count}</span>
                            )
                        </Typography>
                    </Link>
                ) : null}
            </>
        );
    };

    if (loadingRightLink) {
        return (
            <div id="top-header">
                <Skeleton height={38} className={cx('!top-[10px]', '!left-[25%]', '!w-[50vw]')} />
            </div>
        );
    }

    if (!loadingRightLink && dataRightLink && dataRightLink.cmsBlocks
        && dataRightLink.cmsBlocks.items.length > 0 && dataRightLink.cmsBlocks.items[0].content) {
        return (
            <>
                <div
                    id="top-header"
                    className={cx(
                        'top-header',
                        'text-center',
                        'font-normal',
                        'tablet:text-base',
                        'mobile:max-tablet:text-sm',
                        'bg-neutral-white',
                        'text-neutral-black',
                        'mobile:max-tablet:py-1',
                        'border-b',
                        'border-neutral-200',
                        'mobile:max-tablet:max-h-8',
                        !loading && data?.cmsBlocks?.items ? 'h-[39px]' : 'mobile:max-tablet:!p-0',
                    )}
                >
                    <div
                        className={cx(
                            'max-w-[1320px] mx-auto tablet:h-[39px] tablet:p-[10px]',
                            !loading && data?.cmsBlocks?.items ? 'mobile:max-tablet:h-[39px] mobile:max-tablet:p-[10px]' : '',
                        )}
                    >
                        {dataRightLink && !loadingRightLink && (
                            <div className="top-header_right-link float-right mobile:max-tablet:hidden flex flex-row">
                                <ProductCompareContent />
                                <CmsRenderer
                                    content={dataRightLink.cmsBlocks.items[0].content}
                                    {...props}
                                />
                            </div>

                        )}
                        {!loading && data?.cmsBlocks?.items ? (
                            <GlobalPromoCarousel
                                className={cx(
                                    'relative flex justify-start float-left tablet:w-4/6',
                                    'mobile:max-tablet:w-full mobile:max-tablet:min-h-5 mobile:max-tablet:-mt-4',
                                )}
                                content={data?.cmsBlocks?.items[0]?.content}
                                key_cookies={key_cookies}
                                storeConfig={storeConfig}
                                autoPlaySpeed={3000}
                                showNav
                                withArrowNav={false}
                                {...other}
                            />
                        ) : null}
                    </div>

                </div>
                <style jsx global>
                    {`
                        .top-header_right-link ul {
                            display: flex;
                        }
                        .top-header_right-link ul li {
                            padding: 0  20px;
                            font-size: 12px;
                            color: #242424;
                            font-weight: 500;
                            line-height: 1.4;
                        }
                        .top-header_right-link ul li a {
                            line-height: 17px;
                            letter-spacing: .3px;
                        }
                        .top-header_right-link ul li a:hover {
                            text-decoration: underline;
                        }
                        .top-header_right-link ul li:last-of-type {
                            padding-right: 0;
                            border-left: 1px solid #e3e3e3;
                        }
                    `}
                </style>
            </>
        );
    }

    return null;
};

export default GlobalPromoMessage;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import parse, { domToReact } from 'html-react-parser';
import propTypes from 'prop-types';
import React from 'react';

import Button from '@common_button';
import { BREAKPOINTS } from '@core/theme/vars';

import ChevronLeftIcon from '@heroicons/react/20/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon';

const WidgetSliderCarousel = (props) => {
    // WIP : AUTOPLAY
    const {
        content, className, autoPlaySpeed = 5000, withArrowNav = true,
    } = props;

    const [activeTabs, setActiveTabs] = React.useState(0);
    const [showNav, setShowNav] = React.useState(false);
    let countTabs = 0;

    const optionSlider = {
        replace: ({ name, children }) => {
            if (name === 'ul') {
                return (
                    // eslint-disable-next-line no-return-assign
                    <div className={cx('slide-content-wrapper', 'relative', 'mobile:max-tablet:pt-1')}>{domToReact(children, optionSlider)}</div>
                );
            }
            if (name === 'li') {
                countTabs += 1;
                const id = countTabs - 1;
                return (
                    <div
                        className={cx(
                            'slide-content text-[13px] font-medium leading-[17px] hover:underline mobile:max-tablet:leading-6',
                            // {
                            //     hidden: activeTabs !== id,
                            // },
                        )}
                        data-index={id}
                        key={id}
                    >
                        {domToReact(children, optionSlider)}
                    </div>
                );
            }
        },
    };

    const options = {
        replace: ({ attribs, children }) => {
            if (attribs) {
                if (attribs.id !== 'slides') {
                    return <div className="slide-container">{domToReact(children, optionSlider)}</div>;
                }
            }
        },
    };

    React.useEffect(() => {
        if (countTabs > 1) {
            setShowNav(true);
        }
    }, [countTabs]);

    // WIP : AUTOPLAY
    React.useEffect(() => {
        const autoPlayInterval = setInterval(() => {
            if (activeTabs + 1 === countTabs) {
                setActiveTabs(0);
            } else {
                setActiveTabs(activeTabs + 1);
            }
        }, autoPlaySpeed);

        return () => clearInterval(autoPlayInterval);
    }, [activeTabs]);

    if (content && content !== '') {
        return (
            <div className={className}>
                <div className={cx('slider-container', 'overflow-hidden', 'text-center')}>
                    {showNav && withArrowNav ? (
                        <Button
                            onClick={() => {
                                if (activeTabs - 1 < 0) {
                                    setActiveTabs(countTabs - 1);
                                } else {
                                    setActiveTabs(activeTabs - 1);
                                }
                            }}
                            className={cx(
                                'absolute',
                                'mobile:max-tablet:left-[0%]',
                                'tablet:left-[5%]',
                                'tablet:!top-[40%]',
                                'mobile:max-tablet:!top-[50%]',
                                '!py-0',
                                'bg-[transparent]',
                                'translate-y-[-50%]',
                            )}
                            variant="plain"
                            iconOnly
                            icon={<ChevronLeftIcon />}
                            iconProps={{ className: '!text-neutral-white !opacity-100 mobile:max-tablet:h-[20px] mobile:max-tablet:w-[20px]' }}
                        />
                    ) : null}

                    {parse(content, options)}
                    {showNav && withArrowNav ? (
                        <Button
                            onClick={() => {
                                if (activeTabs + 1 === countTabs) {
                                    setActiveTabs(0);
                                } else {
                                    setActiveTabs(activeTabs + 1);
                                }
                            }}
                            className={cx(
                                'absolute',
                                'mobile:max-tablet:right-[0%]',
                                'tablet:right-[5%]',
                                'tablet:!top-[40%]',
                                'mobile:max-tablet:!top-[50%]',
                                '!py-0',
                                'bg-[transparent]',
                                'translate-y-[-50%]',
                            )}
                            variant="plain"
                            iconOnly
                            icon={<ChevronRightIcon />}
                            iconProps={{ className: '!text-neutral-white !opacity-100 mobile:max-tablet:h-[20px] mobile:max-tablet:w-[20px]' }}
                        />
                    ) : null}
                </div>
                <style jsx global>
                    {`
                        .close-btn-widget-slider {
                            background: none;
                        }
                        .slider-container {
                            overflow: hidden;
                            text-align: center;
                            font-size: 14px;
                        }

                        .slide-content {
                            position: absolute;
                            font-size: 13px;
                            animation-duration: 20s;
                            animation-timing-function: ease-in-out;
                            animation-iteration-count: infinite;
                            margin-left: -20%;
                        }
                        
                        .slide-content:nth-child(1) {
                            animation-name: anim-1;
                        }
                        .slide-content:nth-child(2) {
                            animation-name: anim-2;
                        }
                        .slide-content:nth-child(3) {
                            animation-name: anim-3;
                        }

                        @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
                            .slide-content {
                                margin-left: -20%;
                            }
                        }
                        
                        @keyframes anim-1 {
                          0%, 8.3% { left: -100%; opacity: 0; }
                          8.3%,25% { left: 25%; opacity: 1; }
                          33.33%, 100% { left: 80%; opacity: 0; }
                        }
                        
                        @keyframes anim-2 {
                          0%, 33.33% { left: -100%; opacity: 0; }
                          41.63%, 58.29% { left: 25%; opacity: 1; }
                          66.66%, 100% { left: 80%; opacity: 0; }
                        }
                        
                        @keyframes anim-3 {
                          0%, 66.66% { left: -100%; opacity: 0; }
                          74.96%, 91.62% { left: 25%; opacity: 1; }
                          100% { left: 80%; opacity: 0; }
                        }

                        @media (max-width: ${BREAKPOINTS.md - 1}px) {
                            .slider-container {
                                height: auto;
                                padding: 5px 10px;
                                font-size: 12px;
                            }
                        }

                        .btn-bar {
                            display: none;
                        }
                    `}
                </style>
            </div>
        );
    }
    return null;
};

WidgetSliderCarousel.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    content: propTypes.string.isRequired,
    className: propTypes.string,
};

WidgetSliderCarousel.defaultProps = {
    className: '',
};

export default WidgetSliderCarousel;
